import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Img from "gatsby-image"
import { useFirebase } from "gatsby-plugin-firebase"

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  useFirebase(firebase => {
    // initialize google analytics
    firebase.analytics()
  }, [])

  return (
    <Layout>
      <SEO title="All posts" />

      <div className="text-center">
        <img src="/latestNews.png" alt="" className="img-fluid" />
        <p className="pb-3 font-italic">
          Our members are welcome to share their thoughts and ideas.
          <br />
          Please send an email to{" "}
          <a
            href="mailto:webmaster@belmontrunners.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            webmaster@belmontrunners.com
          </a>{" "}
          for more information.
        </p>
      </div>

      <section className="blog_area">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article key={node.fields.slug} className="row blog_item">
              <div className="col-md-3 d-flex justify-content-end">
                <div className="blog_info mb-2">
                  <div className="blog_meta list">
                    <div className="blog_meta_item d-flex justify-content-end">
                      <span className="name">
                        {node.frontmatter.author}
                        <i className="lnr lnr-user" />
                      </span>
                    </div>
                    <div className="blog_meta_item d-flex justify-content-end">
                      <span className="date">
                        {node.frontmatter.date}
                        <i className="lnr lnr-calendar-full" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="blog_post">
                  {node.frontmatter.featuredImage && (
                    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                      <Img
                        sizes={
                          node.frontmatter.featuredImage.childImageSharp.sizes
                        }
                      />
                    </Link>
                  )}
                  <div
                    className="blog_details"
                    style={{ marginBottom: rhythm(1 / 4) }}
                  >
                    <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                      <h2>{title}</h2>
                    </Link>
                  </div>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt
                  }}
                />
                <div className="d-flex justify-content-center">
                  <Link className="blog_btn " to={node.fields.slug}>
                    View More
                  </Link>
                </div>
              </div>
            </article>
          )
        })}
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
